import { faWarning } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import { useEffect } from 'react'

import EmptyStateWithRoody from '~/components/EmptyStateWithRoody'
import useCurrentUser from '~/hooks/useCurrentUser'
import logger from '~/utils/logger'

export function ErrorBoundary() {
  const currentUser = useCurrentUser()
  const error = useRouteError()

  useEffect(() => {
    console.error('ErrorBoundary', error)
    if (!isRouteErrorResponse(error)) {
      logger.fatal(`ErrorBoundary triggered ${window.location.pathname}`, {
        ...(currentUser && {
          user: { id: currentUser.id, email: currentUser.email },
        }),
        extra: { error },
      })
    }
  }, [currentUser, error])

  // catch boundary. thrown errors.
  if (isRouteErrorResponse(error)) {
    return (
      <div className="py-24">
        <EmptyStateWithRoody
          title="Welp"
          body={
            error.data && typeof error.data === 'string' ?
              error.data
            : 'Something went wrong on our end and we could not load this page.'
          }
        />
      </div>
    )
  }

  return (
    <div className="p-6">
      <div className="bg-red-100 text-red-800 border border-red-400 font-medium flex items-center text-center justify-center p-12 rounded-sm">
        <div>
          <FontAwesomeIcon icon={faWarning} className="text-4xl" />
          <div className="mt-6" role="alert">
            Oh no. Something went very wrong on our end.
            <br />
            We've alerted the Kangaroo wranglers.
          </div>
        </div>
      </div>
    </div>
  )
}
